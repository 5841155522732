import React from "react";
import {reduxForm} from "redux-form";
import {FormattedMessage} from "react-intl";
import PropTypes from "prop-types";

import Typography from "@material-ui/core/Typography";
import withStyles from "@material-ui/core/styles/withStyles";

import Button from "../../../common/components/common/Button";
import {RequiredInfo} from "../../../common/components/common/form/FormUtils";
import Dialog from './../../../common/components/common/Dialog'
import {KarmingDataProcessingAgreement} from "../account/dataprotection/KarmingDataProcessingAgreement";
import Spinner from "../../../common/components/common/Spinner";

import {KARMING_TERMS_OF_SERVICE_ADDRESS} from "../../../common/config/karming-links";
import {renderAgreementCheckbox} from "./MerchantOnboarding";
import {
    dataProcessingAgreementValidation,
    passwordValidation,
    privacyPolicyValidation,
    termsOfServiceValidation
} from "../../../common/utils/karming-validators";
import {MAXIMUM_NUMBER_OF_MEMBERS} from "../../utils/business-const";


export const validate = (values) => {
    return Object.assign(
        passwordValidation(
            {name: "password", value: values.password},
            {name: "confirmPassword", value: values.confirmPassword}
        ),
        privacyPolicyValidation({name: "privacyPolicy", value: values.privacyPolicy}),
        termsOfServiceValidation({name: "termsOfService", value: values.termsOfService}),
        dataProcessingAgreementValidation({name: "dataProcessingAgreement", value: values.dataProcessingAgreement}),
    )
};

const styles = {
    list: {
        textAlign: 'left'
    },
    link: {
        zIndex: 9999999
    }
};

class MerchantOnboardingVerificationPageTwo extends React.Component {

    static propTypes = {
        classes: PropTypes.object.isRequired,
        profile: PropTypes.object,
        app: PropTypes.object,
        company: PropTypes.object,
        submitting: PropTypes.bool,
        isInvitedByAdmin: PropTypes.bool,
        handleSubmit: PropTypes.func,
    };

    state = {
        dpaOpen: false,
    };

    handleDpaOpen = () => {
        this.setState({dpaOpen: true});
    };

    handleDpaClose = () => {
        this.setState({dpaOpen: false});
    };

    render() {
        const {app, handleSubmit, submitting, profile, company, isInvitedByAdmin} = this.props;

        if (!company) {
            return <Spinner app={app}/>
        }
        const isSvenskHandel = profile.source === "svenskhandel";

        return (
            <form onSubmit={handleSubmit} autoComplete="on">
                <Typography variant="h5">
                    <FormattedMessage
                        id="confirmActivation"
                        defaultMessage="Confirm Activation"/>
                </Typography>

                {this.renderOfferInfo(isSvenskHandel, isInvitedByAdmin)}

                {renderAgreementCheckbox("termsOfService", this.termsOfServiceLabel)}
                {renderAgreementCheckbox("dataProcessingAgreement", this.dataProcessingAgreementLabel())}

                <Button
                    label={<FormattedMessage
                        id="confirm"
                        defaultMessage="Confirm"/>}
                    type="submit"
                    disabled={submitting}/>

                <RequiredInfo/>
            </form>
        );
    }

    renderOfferInfo(isSvenskHandel, isInvitedByAdmin) {
        const {classes} = this.props;

        const price = isSvenskHandel ? 499 : 799;
        const daysFree = isSvenskHandel ? 30 : 14;

        if (isSvenskHandel || !isInvitedByAdmin) {
            return (
                <React.Fragment>
                    <Typography variant="body1">
                        <FormattedMessage
                            id="smartkundklubbStandard"
                            defaultMessage="Smartkundklubb Standard"/>
                    </Typography>

                    <Typography variant="body1">
                        <FormattedMessage
                            id="merchant-onboarding-verification-page-two.thereafterText"
                            defaultMessage="Prova på gratis I {daysFree} dagar (därefter {price} SEK /månad/säljställe)"
                            values={{
                                daysFree: daysFree,
                                price: price
                            }}/>
                    </Typography>

                    <ul className={classes.list}>
                        <li>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="noBindingPeriod"
                                    defaultMessage="No binding period"/>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="membershipProgram"
                                    defaultMessage="Membership program"/>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="smartCampaignCreation"
                                    defaultMessage="Smart Campaign creation"/>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="smartClubCard"
                                    defaultMessage="Smart Club card"/>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="smartCommunicationFull"
                                    defaultMessage="Smart Communication (SMS* + E-mail)"/>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="emailSendout"
                                    defaultMessage="E-mail send out"/>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="merchant-onboarding-verification-page-two.smsSendoutsText"
                                    defaultMessage="SMS send outs*"/>
                            </Typography>
                        </li>
                        <li>
                            <Typography variant="body1">
                                <FormattedMessage
                                    id="merchant-onboarding-verification-page-two.membersIncluded"
                                    defaultMessage="Up to {memberNumber} members included"
                                    values={{
                                        memberNumber: MAXIMUM_NUMBER_OF_MEMBERS
                                    }}/>
                            </Typography>
                        </li>
                    </ul>

                    <Typography
                        variant="body1"
                        paragraph>
                        <FormattedMessage
                            id="merchant-onboarding-verification-page-two.smsCostSeparate"
                            defaultMessage="*SMS costs are separated"/>
                    </Typography>
                </React.Fragment>
            )
        }

        return (
            <React.Fragment>
                <Typography variant="body1">
                    <FormattedMessage
                        id="smartkundklubb"
                        defaultMessage="Smartkundklubb.se: Partnerbutik"/>
                </Typography>

                <ul className={classes.list}>
                    <li>
                        <Typography variant="body1">
                            <FormattedMessage
                                id="membershipProgram"
                                defaultMessage="Membership program"/>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <FormattedMessage
                                id="smartCampaignCreation"
                                defaultMessage="Smart Campaign creation"/>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <FormattedMessage
                                id="smartClubCard"
                                defaultMessage="Smart Club card"/>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <FormattedMessage
                                id="smartCommunicationFull"
                                defaultMessage="Smart Communication (SMS* + E-mail)"/>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <FormattedMessage
                                id="emailSendout"
                                defaultMessage="E-mail send out"/>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <FormattedMessage
                                id="merchant-onboarding-verification-page-two.smsSendoutsText"
                                defaultMessage="SMS send outs*"/>
                        </Typography>
                    </li>
                    <li>
                        <Typography variant="body1">
                            <FormattedMessage
                                id="merchant-onboarding-verification-page-two.membersIncluded"
                                defaultMessage="Up to {memberNumber} members included"
                                values={{
                                    memberNumber: 1000
                                }}/>
                        </Typography>
                    </li>
                </ul>

                <Typography
                    variant="body1"
                    paragraph>
                    <FormattedMessage
                        id="merchant-onboarding-verification-page-two.smsCostSeparate"
                        defaultMessage="*SMS costs are separated"/>
                </Typography>
            </React.Fragment>
        )
    }

    termsOfServiceLabel = (
        <Typography variant="caption">
            <FormattedMessage
                id="haveReadAndAgreeText"
                defaultMessage="I have read and agree to the"/>
            &nbsp;
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={KARMING_TERMS_OF_SERVICE_ADDRESS}>
                <FormattedMessage
                    id="termsOfService"
                    defaultMessage="Terms of Service"/> *
            </a>
        </Typography>
    );

    dataProcessingAgreementLabel = () => {
        const {classes} = this.props;

        return (
            <div style={{textAlign: "left"}}>
                <Typography
                    variant="caption">
                    Jag bekräftar att jag har läst, förstår och godkänner
                    &nbsp;
                    {/*FIXME clicking changes also checkbox value*/}
                    <a className={classes.link} onClick={this.handleDpaOpen}>
                        Personuppgiftsbiträdesavtalet *
                    </a>
                    &nbsp;
                    som innebär att Karming AB agerar personuppgiftsbiträde för mig som Personuppgiftsansvarig enligt
                    detta
                    avtal. Jag intygar att jag är behörig att ingå avtalet enligt dessa villkor.
                </Typography>

                <Dialog
                    onClose={this.handleDpaClose}
                    title={<FormattedMessage
                        id="dataProcessingAgreement"
                        defaultMessage="Data Processing Agreement"/>}
                    actions={[
                        <Button
                            key="close"
                            label={<FormattedMessage
                                id="close"
                                defaultMessage="Close"/>}
                            onClick={this.handleDpaClose}/>
                    ]}
                    open={this.state.dpaOpen}
                    content={<KarmingDataProcessingAgreement company={this.props.company}/>}/>
            </div>
        )
    }

}

export default reduxForm({
    form: 'merchantOnboardingVerificationForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate: validate
})(withStyles(styles)(MerchantOnboardingVerificationPageTwo));
